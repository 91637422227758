export function useIssuesSearch() {
  const search = useState<string>('ISSUES_SEARCH', () => '')

  function setSearch(value: string) {
    search.value = String(value)
  }

  return {
    search: computed(() => search.value),
    setSearch,
  }
}
